import {
  CHOOSE_CONVERSATIONS_PAGE,
  CHOOSE_CONVERSATIONS_ID,
  CHOOSE_CONVERSATIONS_ENDPOINT,
  CHOOSE_CONVERSATIONS_QUERY,
  CHOOSE_CONVERSATIONS_MODE,
  CHOOSE_CONVERSATIONS_SOURCE,
  CHOOSE_CONVERSATIONS_LEAD_TYPE,
  CHOOSE_CONVERSATIONS_METRIC_TYPE,
  CHOOSE_CONVERSATIONS_SubMetric_TYPE,
  CHOOSE_CONVERSATIONS_PersonId_TYPE,
  CHOOSE_CONVERSATIONS_CHAT_FORM_VALIDATION,
  CHOOSE_CONVERSATIONS_CHAT_FORM_OPEN,
  CHOOSE_COVERSATIONS_CHAT_REFETCH,
} from "../actionTypes/conversationDealerChatTypes";

export const chooseConversationsPage = (page) => {
  return {
    type: CHOOSE_CONVERSATIONS_PAGE,
    payload: page,
  };
};

export const chooseConversationsId = (cid) => {
  return {
    type: CHOOSE_CONVERSATIONS_ID,
    payload: cid,
  };
};

export const chooseConversationsEndpoint = (source) => {
  return {
    type: CHOOSE_CONVERSATIONS_ENDPOINT,
    payload: source,
  };
};

export const chooseConversationsQuery = (query) => {
  return {
    type: CHOOSE_CONVERSATIONS_QUERY,
    payload: query,
  };
};

export const chooseConversationsMode = (mode) => {
  return {
    type: CHOOSE_CONVERSATIONS_MODE,
    payload: mode,
  };
};

export const chooseConversationsSource = (source) => {
  return {
    type: CHOOSE_CONVERSATIONS_SOURCE,
    payload: source,
  };
};

export const chooseConversationLeadType = (leadType) => {
  return {
    type: CHOOSE_CONVERSATIONS_LEAD_TYPE,
    payload: leadType,
  };
};

export const chooseConversationsMetricType = (leadType) => {
  return {
    type: CHOOSE_CONVERSATIONS_METRIC_TYPE,
    payload: leadType,
  };
};

export const chooseConversationsSubMetricType = (leadType) => {
  return {
    type: CHOOSE_CONVERSATIONS_SubMetric_TYPE,
    payload: leadType,
  };
};

export const chooseConversationsPersonId = (personId) => {
  return {
    type: CHOOSE_CONVERSATIONS_PersonId_TYPE,
    payload: personId,
  };
};

export const isChatFormValid = (valid) => {
  return {
    type: CHOOSE_CONVERSATIONS_CHAT_FORM_VALIDATION,
    payload: valid,
  };
};

export const isChatFormOpen = (isOpen) => {
  return {
    type: CHOOSE_CONVERSATIONS_CHAT_FORM_OPEN,
    payload: isOpen,
  };
};

export const refetchChat = (refetch) => {
  return {
    type: CHOOSE_COVERSATIONS_CHAT_REFETCH,
    payload: refetch,
  };
};
