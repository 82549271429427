import CloseIcon from "../../../assets/img/CloseIcon";
import "./ChatCloseFormModal.scss";
import FormElement from "../../FormElement";
import { isChatFormOpen } from "../../../store/actions/conversationDealerChatActions";
import { useDispatch } from "react-redux";

const ChatCloseFormModal = ({ setIsCancelOpen }) => {
  const dispatch = useDispatch();
  return (
    <div className="ChatCloseModalLayout">
      <div className="CloseIconArea">
        <div
          className="CloseIcon"
          onClick={() => {
            setIsCancelOpen(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <h2 className="ChatCloseModalLayout-Title">Close The Form</h2>
      <span className="ChatCloseModalLayout-Desc">
        Clothing this form will discard any unsaved changes. Do you want to
        continue?
      </span>
      <div className="HorizontaButtonsGroup-Area">
        <div className="EachButton">
          <FormElement
            type="submit"
            style={{
              backgroundColor: "white",
              color: "#D62B56",
              borderRadius: "7px",
              border: "1px solid #D62B56",
              fontSize: "10px",
              height: "40px",
            }}
            label="Cancel"
            id="cancelButton"
            name="cancelButton"
            value="Submit message"
            disabled={false}
            onClick={(e) => {
              e.preventDefault();
              setIsCancelOpen(false);
            }}
          />
        </div>
        <div className="EachButton">
          <FormElement
            type="submit"
            style={{
              borderRadius: "7px",
              backgroundColor: "#D62B56",
              borderRadius: "7px",
              border: "1px solid #D62B56",
              fontSize: "10px",
              height: "40px",
            }}
            label="Continue"
            id="submitButton"
            name="submitButton"
            value="Submit message"
            disabled={false}
            onClick={(e) => {
              e.preventDefault();
              dispatch(isChatFormOpen(false));
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ChatCloseFormModal;
