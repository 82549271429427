import "./SettingsDescriptions.scss";

const SettingsDescriptions = ({ id }) => {
  const render = () => {
    switch (id) {
      case "6":
        return (
          <>
            <p className="Settings-TopDescription-bold">
              When writing a custom answer, please keep in mind the following:
            </p>
            <ul className="Settings-TopDescription-ul">
              <li>
                Keep answers as broad as possible. Do not make the answer too
                specific. Answers should be general enough to cover most
                questions related to the topic.
              </li>
              <li>
                Avoid affirmative or negative words, like “great,” “yes” or
                “no.” This keeps the answer as relevant as possible, as it may
                be used in various scenarios-including ones where even the user
                did not ask a question.
              </li>
              <ul className="Settings-TopDescription-nested-ul">
                <li>
                  For example, the user could say, “I would like to trade in my
                  vehicle.” Responding with “Yes, we do accept trade-ins” is not
                  desired.
                </li>
              </ul>
              <li>
                Do not include calls to action (CTAs), as they are already
                automatically applied to the end of each answer. New CTAs in the
                form of questions require additional development work and will
                be rejected.
              </li>
            </ul>
          </>
        );
        break;

      default:
        break;
    }
  };

  return render();
};

export default SettingsDescriptions;
