import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./WidgetAccount.scss";

import Svg from "../Svg";
import FormSelect from "../FormElement/FormSelect";
import { switchAction, switchDealer } from "../../store/actions/userActions";
import { useAuth0 } from "@auth0/auth0-react";

const WidgetAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();

  const auth = useSelector((state) => state.user.auth.data);

  const dispatch = useDispatch();

  const handleChange = async (credentials) => {
    await setIsLoading(true);
    await dispatch(switchDealer(credentials.id))
      .catch((error) => {
        console.log("### Switching user problem: ", error);
      })
      .finally(() => {
        setIsLoading(false);
        window.location.reload();
      });
  };

  const renderStatic = () => {
    return (
      <div className="WidgetAccount">
        <div className="WidgetAccount-text">
          Hi,&nbsp;
          <span className="WidgetAccount-highlight">
            {user?.nickname || "Please login"}
          </span>
        </div>
        <div className="WidgetAccount-icon">
          <Svg w="24" h="24" i="user" />
        </div>
      </div>
    );
  };

  const renderDynamic = () => {
    const accounts = auth.user_info.ChildAccounts;

    const label = "Select user";

    const values = accounts
      .sort(function (a, b) {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map(({ id, name }) => {
        return { id, label: name };
      });

    const defaultValueIdx = accounts.findIndex(
      (el) => el.id === auth.user_info?.DealerId
    );

    return (
      <div className="Header-widget WidgetAccount">
        <FormSelect
          label={label}
          values={values}
          defaultValue={values[defaultValueIdx]}
          onChange={handleChange}
          disabled={isLoading}
        />
      </div>
    );
  };

  return auth.user_info.ChildAccounts ? renderDynamic() : renderStatic();
};

export default WidgetAccount;
