import React from "react";

import cn from "classnames";
import FormTextfield from "./FormTextfield";
import FormTextarea from "./FormTextarea";
import FormPassword from "./FormPassword";
import FormSelect from "./FormSelect";
import FormSelectDealerShip from "./FormSelectDealerShip/FormSelectDealerShip";
import FormCheckbox from "./FormCheckbox";
import FormCheckboxes from "./FormCheckboxes";
import FormRadio from "./FormRadio";
import FormRadios from "./FormRadios";
import FormSubmit from "./FormSubmit";
import FormLinkSubmit from "./FormLinkSubmit";
import FormChoicesLinkSubmit from "./FormChoicesLinkSubmit";
import FormGroup from "./FormGroup";
import FormNumfield from "./FormNumfield";

import "./FormElement.scss";

const Components = {
  textarea: FormTextarea,
  textfield: FormTextfield,
  numfield: FormNumfield,
  password: FormPassword,
  select: FormSelect,
  dealerShipSelect: FormSelectDealerShip,
  checkbox: FormCheckbox,
  checkboxes: FormCheckboxes,
  radio: FormRadio,
  radios: FormRadios,
  submit: FormSubmit,
  linkSubmit: FormLinkSubmit,
  choicesLinkSubmit: FormChoicesLinkSubmit,
  group: FormGroup,
};

const FormElement = ({ type, ...rest }) => {
  if (typeof Components[type] !== "undefined") {
    return (
      <div className={cn("FormElement", `FormElement--${type}`)}>
        {React.createElement(Components[type], { ...rest, type })}
      </div>
    );
  } else {
    return null;
  }
};

export default FormElement;
