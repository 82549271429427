import { useState } from "react";
import "./SplittedSendButton.scss";
import PhoneIcon from "../../../../assets/img/PhoneIcon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { isChatFormOpen } from "../../../../store/actions/conversationDealerChatActions";

const SplittedSendButton = ({ onClickContact, onSubmitClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const isOpen = useSelector((state) => state.conversationDealerChat.isOpen);
  const dispatch = useDispatch();
  console.log("isOpen", isOpen);

  const isMobile = window?.innerWidth <= 768;

  return (
    <div className="SplittedSendButtonArea">
      <button type="submit" className="SendButton" onClick={onSubmitClick}>
        SEND
      </button>
      <div className="dropdown">
        <button
          id="dropdownButton"
          className="dropdown-button"
          type="button"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          {showDropdown ? (
            <KeyboardArrowUpIcon style={{ width: 20, height: 20 }} />
          ) : (
            <KeyboardArrowDownIcon style={{ width: 20, height: 20 }} />
          )}
        </button>
        {showDropdown && (
          <div id="dropdownContent" className="dropdown-content">
            <div
              className="DropdownItem"
              onClick={() => {
                setShowDropdown(false);
                if (isMobile) {
                  onClickContact();
                } else {
                  dispatch(isChatFormOpen(true));
                }
              }}
            >
              <PhoneIcon /> <div className="DropdownItemText">Contact Form</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplittedSendButton;
