import { useState, useEffect } from "react";

import SettingsAnswersCard from "../SettingsAnswersCard";
import Loader from "../../Loader";

import useData from "../../../hook/useData";

import "./SettingsAnswers.scss";
import {useSelector} from "react-redux";

const SettingsAnswers = () => {
  const [state, setState] = useState(null);

  const accountId = useSelector(
      (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const { data, status, error } = useData("getBotAnswersSettings", {}, [accountId]);

  useEffect(() => {
    setState(data && data.Items);
  }, [data]);

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || status === "init" || state === null) {
      return <Loader />;
    }

    const groups = state?.map((group, idx) => {
      return (
        <li key={idx} className="SettingsAnswers-listItem">
          <SettingsAnswersCard key={group.GroupId} group={group} />
        </li>
      );
    });

    return <ul className="SettingsAnswers-list">{groups}</ul>;
  };

  return <div className="SettingsAnswers">{render()}</div>;
};

export default SettingsAnswers;
