import { useEffect, useState, useRef } from "react";

import cn from "classnames";

import FormElement from "../../FormElement/FormElement";
import Loader from "../../Loader/Loader";

import useData from "../../../hook/useData";

import FORM_FIELDS from "./formStructure";

import "./SettingsSignature.scss";

const SettingsCMSSignature = (props) => {
  const [reset, setReset] = useState({});

  return (
    <SettingsSignatureExtended
      {...props}
      previewResetter={reset}
      setPreviewResetter={setReset}
    />
  );
};

const SettingsSignatureExtended = ({ accessConfig = {}, refetch = () => {}, close = () => {}, previewResetter, setPreviewResetter}) => {
  const [mirror, setMirror] = useState(false);
  const [fieldsArr, setFieldsArr] = useState(FORM_FIELDS);
  const [state, setState] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [resourceName, setResourceName] = useState(
    accessConfig?.resource?.Name
  );

  const { accessType, resource } = accessConfig;

  const { data, status, error, execute: mirroringExecute } = useData(
    "getSalesAIAccountGeneralInfo",
    {
      initial_product_id: "LEAD_ENGAGE"
    },
    [],
    false
  );

  const { data: dealerChatData, status: dealerChatStatus, error: dealerChatError } = useData(
    "getDealerChatAccountGeneralInfo",
    {},
    [],
    resource === undefined
  );

  const {
    data: updatedData,
    status: updateStatus,
    execute: updateExecute,
  } = useData("createAndUpdateDealerChatAccountGeneralInfo", {"settings_data": state }, [state], false);

  useEffect(() => {
    if (dealerChatData?.settings_data) {
      setState(dealerChatData?.settings_data);
    }
  }, [dealerChatData]);

  useEffect(() => {
    if (data?.settings_data && mirror) {
      setState(data?.settings_data);
    }
  }, [data]);

  useEffect(() => {
    if (resource) {
      const newState = {
        ...JSON.parse(JSON.stringify(resource.Resource)),
      };

      if (accessType === "write") {
        newState.resource_id = resource.Id;
      }

      setState(newState);
    }
  }, [resource]);

  useEffect(() => {
    if (updateStatus === "success") {
      setUpdateError(null);
      setState(updatedData?.settings_data);
    }
    if (updateStatus === "error") setUpdateError("Could not update signature");
  }, [updateStatus]);

  useEffect(() => {
    mirror && mirroringExecute();
  }, [mirror])

  const handleFieldChange = (fieldStructure, e) => {
    const value = e.target.value;
    setMirror(false)
    resetPreview();

    setState((state) => {
      return {
        ...state,
        MirrorFrom: null,
        [fieldStructure.key]: value,
      };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateExecute();
  };

  const checkboxHandler = (e) => {
    setMirror(value => !value) 
    setState({...state,  MirrorFrom: !state.MirrorFrom })
  };

  const getButtonActionLabel = () => {
    if (accessType === "write") return "save";
    if (accessType === "create") return "create";
    return "save";
  };

  const resetPreview = () => {
    setPreviewResetter(null);
    setTimeout(() => {
      setPreviewResetter({});
      setState((oldState) => ({
        ...oldState
      }));
    }, 1000);
  };

  const render = () => {
    if (dealerChatStatus === "error") {
      return dealerChatError || "Error";
    }

    if (dealerChatStatus === "pending" || state === null) {
      return <Loader />;
    }

    return (
      <>  
        <div className="SettingsCMSSignature_Mirror">
          <FormElement
              type="checkbox"
              label="Same as Sales AI"
              id={`mirror`}
              name={`mirror`}
              checked={mirror || state.MirrorFrom}
              onChange={() => checkboxHandler()}
              disabled={false}
          />
        </div>
        <form
          className={cn("SettingsCMSSignature-form", {
            "SettingsCMSSignature-form--disabled": updateStatus === "pending",
          })}
          onSubmit={handleFormSubmit}
        >
          {fieldsArr.map(({ isWide = false, ...field }, index) => {
            return (
              <div
                key={index}
                className={cn("SettingsCMSSignature-formField", {
                  "SettingsCMSSignature-formField--wide": isWide,
                })}
              >
                <FormElement
                  {...field}
                  defaultValue={state[field.key]}
                  value={state[field.key]}
                  onChange={(e) => handleFieldChange(field, e)}
                  disabled={updateStatus === "pending"}
                  readOnly={accessType === "read"}
                />
              </div>
            );
          })}
          {accessType !== "read" && (
            <div
              key="signature-form-submit"
              className={cn("SettingsCMSSignature-formField", {
                "SettingsCMSSignature-formField--wide": true,
              })}
            >
              <span>{updateError}</span>
              <FormElement
                type="submit"
                label={`${getButtonActionLabel()} signature`}
                disabled={
                  (accessType === "create" && !resourceName) ||
                  !state.DealershipName ||
                  !state.SalesPhoneNumber ||
                  !state.WebsiteURL ||
                  !state.Address
                }
              />
            </div>
          )}
        </form>
      </>
    );
  };

  return <div className="SettingsCMSSignature">{render()}</div>;
};

export default SettingsCMSSignature;
