import cn from "classnames";
import Loader from "../../../Loader/Loader";
import "./TotalLeads.scss";
import QuestionMark from "../../../Tile/QuestionMark/QuestionMark";
import ProgressBar from "../../../ProgressBar/ProgressBar";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { chooseConversationsEndpoint, chooseConversationsMetricType, chooseConversationsSubMetricType } from "../../../../store/actions/conversationActions";

const offline = {
  "total_website_visitors": {
    label: "TOTAL WEBSITE VISITORS",
    description: "Total number of shoppers the AI messaged during the selected date range"
  },
  "total_chat_leads_worked":{
    label: "TOTAL CHAT LEADS WORKED",
    description: "Total number of emails and texts sent by the AI during the selected date range. Note: This metric includes Reply as Assistant messages sent by dealer reps"
  },
  "ai_set_appointments":{
    label: "AI SET APPOINTMENTS",
    description: "Appointments that the AI set without any human intervention during the selected date range"
  },
  "chat_lead_conversation_rate_in_crm":{
    label: "CHAT LEAD CONVERSATION RATE IN CRM",
    description: "Shoppers the AI flagged as needing expertise or next steps from a sales rep during the selected date range. This category also includes customers requesting a call, customers who have provided new phone numbers and shoppers that were handed off to sales and now need urgent response."
  }
}

const TotalLeads = ({ type, objectKey, status, error, dataPrimary, statusCode }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (status === "pending" || status === "init") {
    return (
      <div className="cardContainer">
        <div className="cardheader">
          <div className="cardLabel">
            {offline[objectKey]?.label} <QuestionMark definition={offline[objectKey]?.description} />
          </div>
        </div>
        <div className="error">
          <p>
            <Loader />
          </p>
        </div>
      </div>
    )

  }

  if (status === "error" || (statusCode !== undefined && statusCode !== 200)) {
    return (
      <div className={type === "twin" ? "AISale" : "cardContainer"}>
        <div className="cardheader">
          <div className="cardLabel">
            {offline[objectKey]?.label} <QuestionMark definition={offline[objectKey]?.description} />
          </div>
        </div>
        <div className="error">
          <svg width="35px" height="30px" viewBox="0,0,256,256">
            <g fill="#8777c6" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{"mixBlendMode": "normal"}}>
              <g transform="scale(8.53333,8.53333)">
                <path 
                  d="M15,3c-0.83157,0 -1.54394,0.50672 -1.8457,1.22852l-10.85352,18.71875v0.00195c-0.19588,0.31554 -0.30003,0.67939 -0.30078,1.05078c0,1.10457 0.89543,2 2,2c0.04693,-0.0003 0.09383,-0.00226 0.14063,-0.00586l0.00391,0.00586h10.85547h10.85547l0.00391,-0.00781c0.04677,0.00425 0.09367,0.00686 0.14063,0.00781c1.10457,0 2,-0.89543 2,-2c-0.00039,-0.37206 -0.10455,-0.73663 -0.30078,-1.05273l-0.01562,-0.02734c-0.00065,-0.00065 -0.0013,-0.0013 -0.00195,-0.00195l-10.83594,-18.68945c-0.30177,-0.72179 -1.01413,-1.22852 -1.8457,-1.22852zM13.78711,11.35938h2.42578l-0.20117,6.47266h-2.02344zM15.00391,19.81055c0.822,0 1.31445,0.44227 1.31445,1.19727c0,0.741 -0.49245,1.18164 -1.31445,1.18164c-0.828,0 -1.32422,-0.44064 -1.32422,-1.18164c0,-0.755 0.49522,-1.19727 1.32422,-1.19727z">
                </path>
              </g>
            </g>
          </svg>
          <p>
            There is a problem displaying this metric
          </p>
        </div>
    </div>
    );
  }

  const handleTileClick = (redirectObj) => {
    dispatch(chooseConversationsEndpoint(redirectObj?.endpoint)); 
    dispatch(chooseConversationsMetricType(redirectObj?.metric)); 
    dispatch(chooseConversationsSubMetricType(redirectObj?.sub_metric)); 
    history.push("conversations");
  };
  
  //console.log(dataPrimary?.data[objectKey]?.additional_metrics)

  return (
    <div className={type === "twin" ? "AISale" : "cardContainer"}>
      <div className="cardheader">
        <div className="cardLabel">
          {dataPrimary?.data[objectKey]?.label} <QuestionMark definition={dataPrimary?.data[objectKey]?.description} />
        </div>
        <div className="cardValue">
          <p className={`${dataPrimary?.data[objectKey]?.redirect && "redirectLink"}`} onClick={() => dataPrimary?.data[objectKey]?.redirect && handleTileClick(dataPrimary?.data[objectKey]?.redirect) }>
            {dataPrimary?.data[objectKey]?.count}
          </p>
          {dataPrimary?.data[objectKey]?.rate && <div className="cardValueWithPercent">
            <p><span>{dataPrimary?.data[objectKey]?.rate?.value}%</span>{dataPrimary?.data[objectKey]?.rate?.description}</p>
          </div>}
          {!!dataPrimary?.data[objectKey]?.additional_metrics?.length && <div className="cardValueWithText">
            {
              dataPrimary?.data[objectKey]?.additional_metrics.map((el, index) => (
                <div key={index}>
                  <p>{el?.label}
                    <span className={`${el?.redirect && "redirectLink"}`} onClick={() => el?.redirect && handleTileClick(el?.redirect)}>
                      {(el?.rate === 0 || el?.rate) && el?.rate !== null ? `${el?.rate}%` : el?.count === undefined && <span>&mdash;</span> }
                      {(el?.count === 0 || el?.count) &&  el?.count !== null ? el?.count : el?.rate === undefined && <span>&mdash;</span> }
                    </span>
                  </p>
                </div>
              ))
            }
          </div>}
        </div>
      </div>
      {!!dataPrimary?.data[objectKey]?.bars?.length && <div className="cardProgressBar">
        {
          dataPrimary?.data[objectKey]?.bars.map((el, index) => (
            <ProgressBar 
              key={index}
              leftTitle={el[0]?.filled?.label} 
              leftValue={el[0]?.filled?.count} 
              leftRate={el[0]?.filled?.rate}
              leftRedirect={el[0]?.filled?.redirect || ''}
              rightTitle={el[1]?.outlined?.label} 
              rightValue={el[1]?.outlined?.count} 
              rightRate={el[1]?.outlined?.rate}
              rightRedirect={el[1]?.outlined?.redirect || ''}
            />
          ))
        }
      </div>}
      <div>
        
      </div>
    </div>
  );
};

export default TotalLeads;
