import { useRef, useState } from "react";

import cn from "classnames";

import "./FormSelect.scss";

import Svg from "../../Svg";
import useAutoClose from "../../../hook/useAutoClose";

const FormSelect = ({
  label,
  labelHidden = false,
  icon = false,
  values,
  defaultValue = null,
  disabled = false,
  onChange,
  ...rest
}) => {
  const select = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useAutoClose({ setIsOpen, ref: select });

  const handleChange = (value) => {
    onChange(value);
  };

  const defaultValueCalculated = defaultValue || values[0];

  const listItems = values.map((value) => {
    return (
      <li
        key={value.id}
        className={cn("FormSelect-listItem", {
          "FormSelect-listItems--active":
            value.id === defaultValueCalculated.id,
          "FormSelect-listItems--disabled": value.isDisabled,
        })}
        onClick={() => {
          handleChange(value);
          setIsOpen(false);
        }}
      >
        <span>{value.label}</span>
      </li>
    );
  });

  return (
    <div
      ref={select}
      className={cn("FormSelect", {
        "FormSelect--iconed": icon,
        "FormSelect--open": isOpen,
        "FormSelect--disabled": disabled,
      })}
    >
      <label
        className={cn("FormSelect-label", {
          "FormSelect-label--hidden": labelHidden
        })}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <div className="FormSelect-field">
        {icon && (
          <span className="FormSelect-fieldIcon">
            <Svg {...icon} />
          </span>
        )}
        <button
          className={cn("FormSelect-toggler", {
            "FormSelect-toggler--disabled": disabled
          })}
          type="button"
          disabled={disabled}
          aria-expanded={isOpen}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((isOpen) => !isOpen);
          }}
        >
          <span className="FormSelect-togglerText">
            {defaultValueCalculated.label}
          </span>
          <span className="FormSelect-togglerIcon">
            <Svg w="9" h="6" i="markerDropdown" />
          </span>
        </button>
        <ul className="FormSelect-list" aria-hidden={!isOpen}>
          {listItems}
        </ul>
      </div>
    </div>
  );
};

export default FormSelect;
