import CloseIcon from "../../../assets/img/CloseIcon";
import FormElement from "../../FormElement/FormElement";
import "./CloseFormModalLayout.scss";

const CloseFormModalLayout = ({
  closeModal,
  contactModal,
  shrinkModal,
  isShrinkClose = false,
}) => {
  return (
    <div className="CloseFormModalLayout-Area">
      <div className="CloseIconArea">
        <div
          className="CloseIcon"
          onClick={() => {
            closeModal(false);
            if (isShrinkClose) {
              shrinkModal(true);
            } else {
              contactModal(true);
              shrinkModal(false);
            }
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <h2 className="CloseModalTitle">Close The Form</h2>
      <span className="CloseModalDesc">
        Closing this form will discard any unsaved changes. Do you want to
        continue?
      </span>
      <div className="HorizontaButtonsGroup-Area">
        <div className="EachButton">
          <FormElement
            type="submit"
            style={{
              backgroundColor: "white",
              color: "#D62B56",
              borderRadius: "7px",
              border: "1px solid #D62B56",
              fontSize: "10px",
              height: "40px",
            }}
            label="Cancel"
            id="cancelButton"
            name="cancelButton"
            value="Submit message"
            disabled={false}
            onClick={(e) => {
              e.preventDefault();
              closeModal(false);
              if (isShrinkClose) {
                shrinkModal(true);
              } else {
                contactModal(true);
                shrinkModal(false);
              }
            }}
          />
        </div>
        <div className="EachButton">
          <FormElement
            type="submit"
            style={{
              borderRadius: "7px",
              backgroundColor: "#D62B56",
              borderRadius: "7px",
              border: "1px solid #D62B56",
              fontSize: "10px",
              height: "40px",
            }}
            label="Continue"
            id="submitButton"
            name="submitButton"
            value="Submit message"
            disabled={false}
            onClick={(e) => {
              e.preventDefault();
              closeModal(false);
              shrinkModal(false);
              contactModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CloseFormModalLayout;
