export const CHOOSE_CONVERSATIONS_PAGE =
  "conversations/CHOOSE_CONVERSATIONS_PAGE";

export const CHOOSE_CONVERSATIONS_ID = "conversations/CHOOSE_CONVERSATIONS_ID";

export const CHOOSE_CONVERSATIONS_ENDPOINT =
  "conversations/CHOOSE_CONVERSATIONS_ENDPOINT";

export const CHOOSE_CONVERSATIONS_QUERY =
  "conversations/CHOOSE_CONVERSATIONS_QUERY";

export const CHOOSE_CONVERSATIONS_MODE =
  "conversations/CHOOSE_CONVERSATIONS_MODE";

export const CHOOSE_CONVERSATIONS_SOURCE =
  "conversations/CHOOSE_CONVERSATIONS_SOURCE";

export const CHOOSE_CONVERSATIONS_LEAD_TYPE =
  "conversations/CHOOSE_CONVERSATIONS_LEAD_TYPE";

export const CHOOSE_CONVERSATIONS_METRIC_TYPE =
  "conversations/CHOOSE_CONVERSATIONS_METRIC_TYPE";

export const CHOOSE_CONVERSATIONS_SubMetric_TYPE =
  "conversations/CHOOSE_CONVERSATIONS_SubMetric_TYPE";

export const CHOOSE_CONVERSATIONS_PersonId_TYPE =
  "conversations/CHOOSE_CONVERSATIONS_PersonId_TYPE";

export const CHOOSE_CONVERSATIONS_CHAT_FORM_VALIDATION =
  "conversations/CHOOSE_CONVERSATIONS_CHAT_FORM_VALIDATION";

export const CHOOSE_CONVERSATIONS_CHAT_FORM_OPEN =
  "conversations/CHOOSE_CONVERSATIONS_CHAT_FORM_OPEN";

export const CHOOSE_COVERSATIONS_CHAT_REFETCH =
  "conversations/CHOOSE_COVERSATIONS_CHAT_REFETCH";
