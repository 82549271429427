const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="X">
      <path
        id="Ellipse 150"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F7F7F7"
      />
      <g id="Group 1501">
        <path
          id="Line 155"
          d="M8.25 8.25L15.75 15.75"
          stroke="black"
          stroke-width="1.5"
        />
        <path
          id="Line 156"
          d="M8.25 15.75L15.75 8.25"
          stroke="black"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
);

export default CloseIcon;
