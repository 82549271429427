import { useState } from "react";
import Modal from "@material-ui/core/Modal";

import CustomSwitch from "../../Switch/Switch";
import "./SettingsBotControl.scss";
import useData from "../../../hook/useData";
import Loader from "../../Loader/Loader";

const SettingsBotControl = () => {
  const [openedModal, setOpenedModal] = useState(false);

  const {
    data: is_paused,
    execute: fetchPauseAiStatus,
    status: getPauseAiStatus,
  } = useData("getPauseAiStatus", {}, [], true);

  const { execute: putPauseAiStatus, status: updatePauseAiStatus } = useData(
    "putPauseAiStatus",
    // TODO is_paused needs to be dynamic
    { is_paused: "true" },
    [],
    false
  );

  const handleToggleChange = () => {
    if (is_paused === "true") {
      return;
    }
    setOpenedModal(true);
  };

  const handleModalConfirm = async () => {
    setOpenedModal(false);
    await putPauseAiStatus();
    await fetchPauseAiStatus();
  };

  if (typeof is_paused !== "string") {
    return <p>We had trouble loading Pause AI info</p>;
  }

  return (
    <>
      {getPauseAiStatus === "pending" || updatePauseAiStatus === "pending" ? (
        <Loader />
      ) : (
        <div className="FormSwitch">
          <p className="FormSwitch-ToggleName">off</p>
          <CustomSwitch
            defaultChecked={is_paused === "false"}
            handleToggleChange={handleToggleChange}
          />
          <p className="FormSwitch-ToggleName">on</p>
        </div>
      )}

      <Modal
        open={openedModal}
        onClose={() => {
          setOpenedModal(false);
        }}
        className="ConditionBlock-Modal"
      >
        <div className="SettingsModal-Container">
          <h1 style={{ textAlign: "center", marginBottom: "2vh" }}>
            Are you sure?
          </h1>
          <div style={{ display: "flex", gap: "1rem" }}>
            <button className="FormSubmit-button" onClick={handleModalConfirm}>
              YES
            </button>
            <button
              className="FormSubmit-button"
              style={{ backgroundColor: "#bf3c30" }}
              onClick={() => setOpenedModal(false)}
            >
              NO
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SettingsBotControl;
