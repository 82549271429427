import cn from "classnames";

import Loader from "../../Loader";
import Svg from "../../Svg";

import { formatValue } from "../../../utils/formatValue";
import useData from "../../../hook/useData";

import "./ChatsDetails.scss";
import { useSelector } from "react-redux";
import {useEffect} from "react";



const ChatsDetails = ({ cid, onToggleDetailsCollapsed }) => {
  const isCDK = window.location.hostname.includes('crmaiva');
  const selectedProductId = useSelector(
    (state) => state?.user?.auth?.selectedProduct?.Category
  );

  const { data, status, error } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid]
  );

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    const highlightedEvents = data.HighlightedEvents?.map((event, idx) => {
      const icon = event.Event === "SalespersonReplied" ? "man" : "bell";
      return (
        <div
          key={idx}
          className={cn("ChatsDetails-notification", {
            "is-important": event.HighlightType === 1,
          })}
        >
          <div className="ChatsDetails-notificationIcon">
            <Svg w="22" h="26" i={icon} />
          </div>
          <div className="ChatsDetails-notificationText">{event.EventName}</div>
        </div>
      );
    });

    return (
      <>
        <div className="ChatsDetails-heading">
          <h2 className="ChatsDetails-name">{data.FullName}</h2>
          <button
            className="ChatsDetails-toggler"
            onClick={onToggleDetailsCollapsed}
          >
            <span className="ChatsDetails-togglerLabel">Hide window</span>
            <span className="ChatsDetails-togglerIcon">
              {" "}
              <Svg w="24" h="7" i="arrowRight" />
            </span>
          </button>
        </div>
        <div className="ChatsDetails-info">
          <div className="ChatsDetails-infoLabel">Email:</div>
          <div className="ChatsDetails-infoValue">{data.Email}</div>

          <div className="ChatsDetails-infoLabel">Phone:</div>
          <div className="ChatsDetails-infoValue">{data.Phone}</div>

          <div className="ChatsDetails-infoLabel">Lead type:</div>
          <div className="ChatsDetails-infoValue">{data.Channel}</div>

          <div className="ChatsDetails-infoLabel">Lead source:</div>
          <div className="ChatsDetails-infoValue">{data.LeadSource}</div>

          {selectedProductId !== "SERVICE_AI" ? (
            <>
              <div className="ChatsDetails-infoLabel is-full">Lead status:</div>
              <div className="ChatsDetails-infoValue is-full">
                {data.LeadStatus}
              </div>

              <div className="ChatsDetails-infoLabel">Assigned to:</div>
              <div className="ChatsDetails-infoValue">{data.AssignedTo}</div>
            </>
          ) : null}
        </div>

        <div className="ChatsDetails-actions">
          {data.CrmProfileUrl && (
            <a
              className="ChatsDetails-action"
              href={data.CrmProfileUrl}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ChatsDetails-actionLabel">CRM Profile</span>
              <span className="ChatsDetails-actionIcon">
                <Svg w="25" h="7" i="arrowRight" />
              </span>
            </a>
          )}

          {(isCDK || data.ReplyAssistant) && (
            <a
              className="ChatsDetails-action"
              href={isCDK ? window.location.href : data.ReplyAssistant}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ChatsDetails-actionLabel">
                Reply as Assistant
              </span>
              <span className="ChatsDetails-actionIcon">
                <Svg w="25" h="7" i="arrowRight" />
              </span>
            </a>
          )}

          {(isCDK || data.StopAssistant) && (
            <a
              className="ChatsDetails-action is-danger"
              href={isCDK ? `${window.location.pathname}/stop-communication/` : data.StopAssistant}
              target="_blank"
              rel="noreferrer"
            >
              <span className="ChatsDetails-actionLabel">Stop Assistant</span>
              <span className="ChatsDetails-actionIcon">
                <Svg w="25" h="7" i="arrowRight" />
              </span>
            </a>
          )}
        </div>

        {data.VehicleInfo && (
          <div className="ChatsDetails-object">
            {data.VehicleInfo.Price && (
              <>
                <div className="ChatsDetails-objectName">
                  {data.VehicleInfo.Vehicle}
                </div>
                <div className="ChatsDetails-objectInfo">
                  <div className="ChatsDetails-objectValue">{`$${formatValue(
                    +data.VehicleInfo.Price
                  )}`}</div>
                  <div
                    className={cn("ChatsDetails-objectStatus", {
                      "ChatsDetails-objectStatus--available":
                        data.VehicleInfo.Available,
                      "ChatsDetails-objectStatus--unavailable": !data
                        .VehicleInfo.Available,
                    })}
                  >
                    {data.VehicleInfo.Available ? "Available" : "Unavailable"}
                  </div>
                </div>
              </>
            )}
            {data.VehicleInfo.ImageUrl && (
              <div className="ChatsDetails-objectPhoto">
                <img src={data.VehicleInfo.ImageUrl} alt="" />
              </div>
            )}
          </div>
        )}

        <div className="ChatsDetails-notifications">{highlightedEvents}</div>
      </>
    );
  };

  return <div className="ChatsDetails">{render()}</div>;
};

export default ChatsDetails;
