import { useCallback, useEffect, useState } from "react";

import cn from "classnames";

import FormElement from "../../FormElement";
import Loader from "../../Loader";

import useData from "../../../hook/useData";

import "./SettingsFollow.scss";

const SettingsFollowCard = ({ data, handleChange }) => {
  const status = !!data.Status;
  const id = data.FollowUpId;
  const label = data.ReferencePoint;
  const value = data.Interval.Value;

  return (
    <div
      className={cn("SettingsFollowCard", {
        "SettingsFollowCard--stateOn": status,
        "SettingsFollowCard--stateOff": !status,
      })}
    >
      <div className="SettingsFollowCard-toggler">
        <FormElement
          type="checkbox"
          label={id}
          name={label}
          checked={status}
          onChange={() => handleChange(id)}
        />
      </div>
      {value > 0 && (
        <div className="SettingsFollowCard-period">
          <div className="SettingsFollowCard-periodValue">{value}</div>
          <div className="SettingsFollowCard-periodLabel">{label}</div>
        </div>
      )}
    </div>
  );
};

const SettingFollowBlock = ({ data, handleUpdateBlock }) => {
  const [formData, setFormData] = useState(null);

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData("updateAccountFollowUpSettings", formData, [formData], false);

  const blockId = data.FollowUpSequenceId;

  useEffect(() => {
    if (formData) {
      updateExecute();
    }
  }, [formData, updateExecute]);

  useEffect(() => {
    if (updateStatus === "success") {
      handleUpdateBlock(blockId, updateData);
      setFormData(null);
    }
  }, [updateStatus, blockId, updateData, handleUpdateBlock]);

  const handleChange = (cardId) => {
    const cardIdx = data.FollowUpSequence.findIndex(
      (card) => card.FollowUpId === cardId
    );

    const card = data.FollowUpSequence[cardIdx];
    const status = card.Status ? 0 : 1;

    const sequence = [
      ...data.FollowUpSequence.slice(0, cardIdx),
      { ...card, Status: status },
      ...data.FollowUpSequence.slice(cardIdx + 1),
    ];
    const formData = {
      FollowUpSequence: sequence,
      FollowUpSequenceId: data.FollowUpSequenceId,
    };

    setFormData(formData);
  };

  const cards = data.FollowUpSequence.map((item) => {
    return (
      <SettingsFollowCard
        key={item.FollowUpId}
        data={item}
        handleChange={handleChange}
      />
    );
  });

  return (
    <div
      className={cn("SettingsFollowBlock", {
        "SettingsFollowBlock--disabled": updateStatus === "pending",
      })}
    >
      <h3 className="SettingsFollowBlock-label">{data.FollowUpSequenceName}</h3>
      <div className="SettingsFollowBlock-cards">{cards}</div>
    </div>
  );
};

const SettingsFollow = () => {
  const [state, setState] = useState(null);
  const { data, status, error } = useData("getAccountFollowUpSettings", {}, []);

  useEffect(() => {
    setState(data);
  }, [data]);

  const handleUpdateBlock = useCallback((id, blockData) => {
    setState((state) => {
      const stateBlockIdx = state.findIndex(
        (block) => block.FollowUpSequenceId === id
      );
      const dataBlockIdx = blockData.findIndex(
        (block) => block.FollowUpSequenceId === id
      );

      return [
        ...state.slice(0, stateBlockIdx),
        blockData[dataBlockIdx],
        ...state.slice(stateBlockIdx + 1),
      ];
    });
  }, []);

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || status === "init" || state === null) {
      return <Loader />;
    }

    const blocksData = state.sort((a, b) => a.SortNumber - b.SortNumber);

    const blocks = blocksData.map((item) => {
      return (
        <SettingFollowBlock
          key={item.FollowUpSequenceId}
          data={item}
          handleUpdateBlock={handleUpdateBlock}
        />
      );
    });

    return blocks;
  };

  return <div className="SettingsFollow">{render()}</div>;
};

export default SettingsFollow;
