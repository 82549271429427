import {useState} from "react";
import cn from "classnames";

import TableContext from "./TableContext";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import sortObjects from "./helpers/sortObjects";

import "./Table.scss";

const Table = ({
                   columns,
                   rows: rowsData,
                   events = {},
                   options = {},
                   sortArray = true,
                   scrollable = false,
                   setFreezeTable,
               }) => {
    const rows = Array.isArray(rowsData) ? rowsData : [];
    const rowsToShow = 5;
    const {fillParent} = options;

    const [collapsed, setCollapsed] = useState(!fillParent);
    const [expandedData, setExpandedData] = useState(null)

    const [sort, setSort] = useState({
        column: options.sortBy || null,
        direction: "desc",
    });

    const sortedData = sortArray
        ? sortObjects(rows, sort.column, sort.direction)
        : rows;

    const rowsMarkup = sortedData.map((row, idx) => {
        if (!scrollable && collapsed && idx >= rowsToShow) return false;
        if (row.SubSourceNum === null) return false;

        return (<>
                <TableRow
                    className={cn(idx % 2 ? "TableRow--even" : "TableRow--odd", {
                        "TableRow--first": idx === 0,
                        "TableRow--last": idx === sortedData.length - 1,
                        "TableRow--disabled": row.status === false,
                    })}
                    key={row[columns[0].id]}
                    row={row}
                    events={events}
                    setFreezeTable={setFreezeTable}
                />
            </>
        );
    });

    const visibleColumns = columns.filter((column) => !column.hiddenColumn);

    const handleToggleShowAll = () => {
        setCollapsed((state) => !state);
    };

    return (
        <TableContext.Provider value={[{columns, sort}, setSort]}>
            <>
                <div
                    className={cn(
                        scrollable ? "TableContainerScrollable" : "TableContainer",
                        {
                            "TableContainer--fillParent": fillParent,
                        }
                    )}
                >
                    <div
                        className="TableContainer-table"
                        style={{
                            minWidth:
                                visibleColumns.length * (options.baseColumnWidth || 120) + "px",
                        }}
                    >
                        <table
                            className={cn("Table", {
                                "Table--layoutFixed": options.layoutFixed,
                            })}
                        >
                            <TableHead sticky={fillParent} textColor={options?.color || ""}/>
                            <tbody className="Table-body">{rowsMarkup}</tbody>
                        </table>
                    </div>
                </div>
                {!scrollable && rows.length > rowsToShow && !fillParent && (
                    <button className="Table-expandButton" onClick={handleToggleShowAll}>
                        {collapsed ? "Show more" : "Show less"}
                    </button>
                )}
            </>
        </TableContext.Provider>
    );
};

export default Table;
