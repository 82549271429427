// const host = process.env.NODE_ENV === "development" ? "analytics-test.pulsar.ai" : "analytics.pulsar.ai"

const stage = process.env.REACT_APP_STAGE || "DEVELOPMENT";

const config = {
  client: {
    server: {
      protocol: "https",
      host: process.env[`REACT_APP_HOST_${stage}`],
      // protocol: "http",
      // host: "127.0.0.1:5000",
    },
    endpoints: {
      /* ******************************************
              Start New Dasboard cards api endPoint
            ****************************************** */
      total_leads_worked: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/total_leads_worked",
        },
      },
      total_messages_sent: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/total_messages_sent",
        },
      },
      existing_leads_engaged: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/existing_leads_engaged",
        },
      },
      new_leads_engaged: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/new_leads_engaged",
        },
      },
      ai_set_appointments: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/ai_set_appointments",
        },
      },
      ai_sales_handoffs: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/ai_sales_handoffs",
        },
      },
      ai_assisted_sales: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/ai_assisted_sales",
        },
      },
      filter_metrics: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/filters/metrics",
        },
      },
      /* ******************************************
              End New Dasboard cards api endPoint
            ****************************************** */
      /* ******************************************
              Start New Dasboard cards api endPoint DealerChat
            ****************************************** */
      total_website_visitors: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/total_website_visitors",
        },
      },
      total_chat_leads_worked: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/total_chat_leads_worked",
        },
      },
      chat_lead_conversation_rateInCRM: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/chat_lead_conversation_rate_in_crm",
        },
      },
      lead_events_registered: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/lead_events_registered",
        },
      },
      dealerChat_sales: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/sales",
        },
      },
      dealerChat_engagement_chart_table: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/engagement_chart",
        },
      },
      /* ******************************************
              End New Dasboard cards api endPoint DealerChat
            ****************************************** */
      /* ******************************************
              Start Conversations API with metric and submetric
            ****************************************** */
      /* Dealer Chat */
      conversations_total_chat_leads_worked: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/total_chat_leads_worked",
        },
      },
      conversations_total_website_visitors: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/total_website_visitors",
        },
      },
      conversations_chat_lead_conversation_rate_in_crm: {
        method: "GET",
        uri: {
          pathname:
            "api/dashboard/conversations/chat_lead_conversation_rate_in_crm",
        },
      },
      conversations_lead_events_registered: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/lead_events_registered",
        },
      },
      /* Dealer Chat */
      /* Lead Engage */
      conversations_total_leads_worked: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/total_leads_worked",
        },
      },
      conversations_total_messages_sent: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/total_messages_sent",
        },
      },
      conversations_existing_leads_engaged: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/existing_leads_engaged",
        },
      },
      conversations_new_leads_engaged: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/new_leads_engaged",
        },
      },
      conversations_ai_set_appointments: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/ai_set_appointments",
        },
      },
      conversations_ai_sales_handoffs: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/ai_sales_handoffs",
        },
      },
      conversations_ai_assisted_sales: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/ai_assisted_sales",
        },
      },
      conversations_ai_assisted_vechicle_sales: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/ai_assisted_vechicle_sales",
        },
      },
      conversations_ai_assisted_gross_sales: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/ai_assisted_gross_sales",
        },
      },
      conversations_salesperson_performance: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/salesperson_performance",
        },
      },
      conversations_source_conversations: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/source_conversations",
        },
      },
      /* Lead Engage */
      /* ******************************************
              End Conversations API with metric and submetric
            ****************************************** */
      // Dashboard
      ctaConvensions: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/cta_conversions",
        },
      },

      closedRos: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/closed_ros",
        },
      },

      leadSourceStatistics: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/lead_source_statistics",
        },
      },

      salespersonPerformance: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/salesperson_performance",
        },
      },

      engagementStatistics: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/engagement_statistics",
        },
      },

      totalSales: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/total_sales",
        },
      },

      aiAppointments: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/ai_appointments",
        },
      },

      messagesOut: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/messages_out",
        },
      },

      duplicateLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/duplicate_leads",
        },
      },

      htoStatistics: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/hto_statistics",
        },
      },

      acquiredPhoneNumbers: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/acquired_phone_numbers",
        },
      },

      followUpStatistics: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/follow_up_statistics",
        },
      },

      // htoStatistics: {
      //   method: "GET",
      //   uri: {
      //     pathname: "api/dashboard/metrics/hto_statistics",
      //   },
      // },

      closedDeals: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/metrics/closed_deals",
        },
      },

      // Conversations list
      engagedLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/engaged_leads",
        },
      },

      ctaConversions: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/cta_conversions",
        },
      },

      confirmedPurchases: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/confirmed_purchases",
        },
      },

      businessOfficeHandOffs: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/sales_expertise_needed",
        },
      },

      aiAppointmentsLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/ai_appointments",
        },
      },

      salesExpertiseNeededLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/sales_expertise_needed",
        },
      },

      acquiredPhoneNumbersLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/acquired_phone_numbers",
        },
      },

      losingOpportunitiesLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/losing_opportunities",
        },
      },

      newProcessedLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/customer_messaged",
        },
      },

      closedRosPage: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/closed_ros",
        },
      },

      conversationsSearch: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/search",
        },
      },

      newBySourceLeads: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/conversations/source_conversations",
        },
      },

      // Single conversation
      /* Dealer Chat */
      dialogueDealerChat: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/chat/dialogue",
        },
      },
      /* Dealer Chat */
      dialogue: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dialogue",
        },
      },

      getGeneralLeadInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/leads/{cid}/general-info",
        },
      },

      getChatEventsData: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/events",
        },
      },

      getStatus: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dialogue/statuses",
        },
      },

      getNotificationsInvoked: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dialogue/notifications",
        },
      },

      getStopBotEvents: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dialogue/stop-bot",
        },
      },

      getEvents: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dialogue/events",
        },
      },

      updateCommunication: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/dialogue/update-communication",
        },
      },

      getUserInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dialogue/user-info",
        },
      },

      //Settings DealerChat
      getDealerChatAccountGeneralInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dealer-settings/{account_id}/{service_type}",
        },
      },

      getSalesAIAccountGeneralInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dealer-settings/{account_id}/{service_type}",
        },
      },

      getDealershipTakeoverStatus: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/dealership-takeover-status",
        },
      },

      getConvensionTakeoverStatus: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/maximize-conversion-status",
        },
      },

      updateDealershipTakeoverStatus: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/{account_id}/dealership-takeover-status",
        },
      },
      updateConversionStatus: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/{account_id}/maximize-conversion-status",
        },
      },
      createContactForm: {
        method: "POST",
        uri: {
          pathname:
            "api/dashboard/{account_id}/dealership-takeover-submit-form?user_id={user_id}",
        },
      },

      updateDealershipTakeoverState: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/{account_id}/dealership-takeover-state",
        },
      },

      getDealerChatCMSInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/CMS/{account_id}/{service_type}",
        },
      },

      updateAnswer: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/CMS/{account_id}/{service_type}",
        },
      },

      addQuestionOrTopic: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/CMS/{account_id}/{service_type}",
        },
      },

      deleteQuestionOrTopic: {
        method: "DELETE",
        uri: {
          pathname: "api/dashboard/CMS/{account_id}/{service_type}",
        },
      },

      getDealerChatNotifications: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/notifications/{account_id}/{service_type}",
        },
      },

      getDealerChatNotificationSettingsSubscriptions: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/subscriptions",
        },
      },
      getDealerChatNotificationSettingsSubscriptionsPrincipals: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/principals",
        },
      },

      createDealerChatSubscriberData: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/notifications/{account_id}/{service_type}",
        },
      },

      updateDealerChatSubscriberData: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/notifications/{account_id}/{service_type}",
        },
      },

      deleteDealerChatSubscriber: {
        method: "DELETE",
        uri: {
          pathname: "api/dashboard/notifications/{account_id}/{service_type}",
        },
      },

      // Settings
      getAccountGeneralInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/general",
        },
      },

      updateAccountGeneralInfo: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/general",
        },
      },

      getAccountWorkingHours: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/working-hours",
        },
      },

      getAccountBusinessHours: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/business-hours/{account_id}/{service_type}",
        },
      },

      createAndUpdateAccountBusinessHours: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/business-hours/{account_id}/{service_type}",
        },
      },

      updateAccountWorkingHours: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/working-hours",
        },
      },
      createAndUpdateDealerChatAccountGeneralInfo: {
        method: "POST",
        uri: {
          pathname:
            "/api/dashboard/dealer-settings/{account_id}/{service_type}",
        },
      },
      getAccountAppointmentHours: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/appointment-hours",
        },
      },

      updateAccountAppointmentHours: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/appointment-hours",
        },
      },

      getBotAnswersSettings: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/bot-answers/_all",
        },
      },

      updateBotAnswersSettings: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/{account_id}/settings/bot-answers/{group_id}",
        },
      },

      getAccountSubSourceSettings: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/sub-sources",
        },
      },

      updateAccountSubsourceSettings: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/sub-sources",
        },
      },

      getAccountSourceSettings: {
        method: "GET",
        uri: {
          pathname:
            "api/dashboard/{account_id}/settings/sources/limitations/_all",
        },
      },

      updateAccountSourceSettings: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/{account_id}/settings/sources/limitations/{source_id}",
        },
      },

      // Settings Notifications
      getAccountNotificationSettings: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/notifications/_all",
        },
      },
      addAccountNotificationSettingsPersonnel: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/notifications/_all",
        },
      },
      updateAccountNotificationSettingsPersonnel: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/{account_id}/settings/notifications/{UserId}",
        },
      },
      deleteAccountNotificationSettingsPersonnel: {
        method: "DELETE",
        uri: {
          pathname:
            "api/dashboard/{account_id}/settings/notifications/{UserId}",
        },
      },

      getAccountNotificationSettingsSubscriptions: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/subscriptions",
        },
      },
      getAccountNotificationSettingsSubscriptionsPrincipals: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/principals",
        },
      },

      getAccountFollowUpSettings: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/settings/follow-up/_all",
        },
      },
      updateAccountFollowUpSettings: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/{account_id}/settings/follow-up/{FollowUpSequenceId}",
        },
      },

      // DealerParams
      getDealerALLParams: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/dealer-parameters",
        },
      },
      getDealerParams: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/dealer-parameters/{account_id}",
        },
      },
      updateDealerParams: {
        method: "POST",
        uri: {
          pathname: "/api/dashboard/dealer-parameters/{account_id}",
        },
      },

      // User
      getProfileInfo: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/profile/{accountId}",
        },
      },
      updateProfileInfo: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/profile/{accountId}",
        },
      },
      getDealerProducts: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/users/{dealerId}",
        },
      },
      getDealerSettings: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/dealers/{dealerId}/settings",
        },
      },
      updateCDKUser: {
        method: "PUT",
        uri: {
          pathname: "api/dashboard/update-cdk-user",
        },
      },
      getUserMetadata: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/users/{accountId}/metadata",
        },
      },

      // Auth
      login: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/login",
        },
      },
      logout: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/logout",
        },
      },
      switch: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/switch-user",
        },
      },

      // Campaigns
      getCampaignTypes: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/campaigns/setup",
        },
      },
      getCampaignCategories: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/campaigns/setup/{campaign_type}",
        },
      },
      // getCampaignSequences: {
      //   method: "GET",
      //   uri: {
      //     pathname:
      //       "api/dashboard/campaigns/setup/{campaign_type}/{campaign_category}",
      //   },
      // },
      getCampaignSequences: {
        method: "GET",
        uri: {
          pathname:
            "api/dashboard/campaigns/setup/{campaign_type}/{campaign_category}/{account_id}",
        },
      },
      createCampaignConfiguration: {
        method: "POST",
        uri: {
          pathname:
            "api/dashboard/campaigns/setup/{campaign_type}/{campaign_category}/{campaign_sequence}/{account_id}",
        },
      },
      updateCampaignConfiguration: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/campaigns/setup/{campaign_type}/{campaign_category}/{campaign_sequence}/{account_id}",
        },
      },
      processCampaignsData: {
        method: "POST",
        uri: {
          pathname:
            "/api/dashboard/campaigns/setup/process-data/{campaign_type}/{campaign_category}/{campaign_sequence}/{account_id}",
        },
      },

      getAvailableWorkingHoursResources: {
        method: "GET",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/working-hours/_available",
        },
      },
      getAvailableGeneralInfoResources: {
        method: "GET",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/general-info/_available",
        },
      },
      getAvailableNotificationsResources: {
        method: "GET",
        uri: {
          pathname:
            "/api/dashboard/{account_id}/resources/notifications/_available",
        },
      },

      getBlankWorkingHoursResource: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/resources/working-hours/_blank",
        },
      },
      getBlankGeneralInfoResource: {
        method: "GET",
        uri: {
          pathname: "api/dashboard/{account_id}/resources/general-info/_blank",
        },
      },

      updateAccountWorkingHoursResource: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/working-hours/{resource_id}",
        },
      },
      updateAccountGeneralInfoResource: {
        method: "PUT",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/general-info/{resource_id}",
        },
      },
      updateNotificationsResource: {
        method: "PUT",
        uri: {
          pathname: "/api/dashboard/{account_id}/resources/notifications/{Id}",
        },
      },

      createAccountWorkingHoursResource: {
        method: "POST",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/working-hours/_create",
        },
      },
      createAccountGeneralInfoResource: {
        method: "POST",
        uri: {
          pathname: "api/dashboard/{account_id}/resources/general-info/_create",
        },
      },
      createNotificationsResource: {
        method: "POST",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/notifications/_create",
        },
      },

      deleteAccountNotificationSettingsPersonnelResource: {
        method: "DELETE",
        uri: {
          pathname:
            "api/dashboard/{account_id}/resources/notifications/{SubscriptionId}",
        },
      },

      getEmailObject: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/follow-up/template/{template_id}",
        },
      },

      // Preferences
      getPreferences: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/{account_id}/preferences",
        },
      },
      updatePreferences: {
        method: "POST",
        uri: {
          pathname: "/api/dashboard/{account_id}/preferences",
        },
      },

      // user management page
      inviteUsers: {
        method: "POST",
        uri: {
          pathname: "/api/dashboard/users",
        },
      },
      updateUser: {
        method: "PUT",
        uri: {
          pathname: "/api/dashboard/users",
          query: { user_id: "" },
        },
      },
      queryUsers: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/users",
        },
      },
      deleteUser: {
        method: "DELETE",
        uri: {
          pathname: "/api/dashboard/users",
          query: { user_id: "" },
        },
      },

      // other
      getCrmUser: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/{account_id}/notifications/crm-user",
        },
      },

      notifyTeam: {
        method: "POST",
        uri: {
          pathname: "/api/dashboard/{account_id}/notify-team",
        },
      },

      replyMessage: {
        method: "POST",
        uri: {
          pathname: "/api/dashboard/message/{account_id}",
        },
      },

      getSignature: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/signature/{account_id}",
        },
      },
      isUnsubscribed: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/unsubscribed/{account_id}",
        },
      },
      getPauseAiStatus: {
        method: "GET",
        uri: {
          pathname: "/api/dashboard/{account_id}/pause-ai",
        },
      },
      putPauseAiStatus: {
        method: "PUT",
        uri: {
          pathname: "/api/dashboard/{account_id}/pause-ai",
        },
      },
      // END
    },
  },
};

export default config;
