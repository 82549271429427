import React, { useState } from "react";
import "./ChatTabs.scss";
import { useDispatch, useSelector } from "react-redux";
import { isChatFormOpen } from "../../../store/actions/conversationDealerChatActions";
import ChatConversationContext from "../ChatConversationContext/ChatConversationContext";
import Close from "../../../assets/img/Close";
import CloseIcon from "@material-ui/icons/Close";
import ChatContactForm from "../ChatContactForm/ChatContactForm";
import Modal from "@material-ui/core/Modal";
import ChatCloseFormModal from "../ChatCloseFormModal/ChatCloseFormModal";

const ChatTabs = ({
  data,
  onToggleDetailsCollapsed,
  selectedProductId,
  highlightedEvents,
  generalInfoData,
  refetchGeneralInfoData,
  setShowSnackbar,
  setRefetchGeneralInfo
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.conversationDealerChat.isOpen);
  const [isCancelOpen, setIsCancelOpen] = useState(false);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <div className="tabs">
        <Modal
          open={isCancelOpen}
          onClose={() => {
            setIsCancelOpen(false);
          }}
          className="Chat-Form-Modal-Desktop"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ChatCloseFormModal setIsCancelOpen={setIsCancelOpen} />
        </Modal>
        <div className="tab-list">
          <div
            className={`tab ${activeTab === 0 ? "active" : "inactive"}`}
            onClick={() => handleTabClick(0)}
          >
            Conversation Context
          </div>
          <div
            className={`tab ${activeTab === 1 ? "active" : "inactive"}`}
            onClick={() => handleTabClick(1)}
          >
            Submit a Form{" "}
            <div
              className="close-icon"
              onClick={() => {
                setIsCancelOpen(true);
              }}
            >
              <CloseIcon style={{ width: "20px", color: "black" }} />
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div style={activeTab !== 0 ? { display: "none" } : {}}>
            <ChatConversationContext
              data={data}
              onToggleDetailsCollapsed={onToggleDetailsCollapsed}
              selectedProductId={selectedProductId}
              highlightedEvents={highlightedEvents}
            />
          </div>
          <div style={activeTab === 0 ? { display: "none" } : {}}>
            <ChatContactForm
              generalInfoData={generalInfoData}
              refetchGeneralInfoData={refetchGeneralInfoData}
              setShowSnackbar={setShowSnackbar}
              setRefetchGeneralInfo={setRefetchGeneralInfo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatTabs;
