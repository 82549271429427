import { useEffect, useState, useRef } from "react";
import FormElement from "../../FormElement/FormElement";
import Loader from "../../Loader/Loader";
import useData from "../../../hook/useData";
import "./SettingsNotifyTeam.scss";

const NotifyTeam = (props) => {
  const { data, execute, status } = useData("notifyTeam", {}, [], false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (status === "success") {
      setMessage(data.Message);
    }
    if (status === "error") {
      setMessage(data);
    }
  }, [status]);

  const handleSubmit = () => {
    setMessage(null);
    execute();
  };

  return (
    <div className="SettingsNotifyTeam-Container">
      {status === "pending" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <FormElement type="submit" label="Notify Team" onClick={handleSubmit} />
      )}
      {message && (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1vh",
          }}
        >
          {typeof message === "string" ? message : JSON.stringify(message)}
        </span>
      )}
    </div>
  );
};

export default NotifyTeam;
