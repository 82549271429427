import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Svg from "../Svg";
import FormElement from "../FormElement";
import CircularProgress from "@mui/material/CircularProgress";

const Confirmation = ({
  isOpen,
  closeDialog,
  title,
  text,
  cancelButtonTitle,
  confirmButtonTitle,
  handleConfirmDelete,
  loading,
}) => {
  return (
    <Dialog
      aria-labelledby="dialog-title"
      open={isOpen}
      className="Dialog-modal"
    >
      <DialogTitle id="dialog-title" className="DeleteDialog-header">
        <span>{title}</span>
        <button
          type="button"
          onClick={closeDialog}
          className="DeleteDialog-close"
        >
          <Svg w="16" h="16" i="close" />
        </button>
      </DialogTitle>
      <DialogContent>
        <p className="DeleteDialog-text">
          {loading ? <CircularProgress /> : text}
        </p>

        <div className="DeleteDialog-actions">
          <FormElement
            className="DeleteDialog-cancelButton"
            type="submit"
            onClick={closeDialog}
            label={cancelButtonTitle}
          />
          <FormElement
            type="submit"
            label={confirmButtonTitle}
            onClick={handleConfirmDelete}
            disabled={loading}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Confirmation;
