import React, { useEffect, useState } from "react";
import cn from "classnames";

import SettingsNotificationsForm from "../SettingsNotificationsForm";
import SettingsNotificationsPerson from "../SettingsNotificationsPerson";

import Svg from "../../Svg";
import Loader from "../../Loader";

import useData from "../../../hook/useData";

import "./SettingsNotifications.scss";
import {useDispatch, useSelector} from "react-redux";
import {savePrincipals} from "../../../store/actions/settingActions";

const SettingsNotifications = ({
  campaigns = false,
  selectPerson = () => {},
  selectedPersons = new Set(),
}) => {
  const reduxDispatch = useDispatch();
  const [state, setState] = useState(null);
  const [personEdit, setPersonEdit] = useState(null);
  const [personDelete, setPersonDelete] = useState(null);
  const accountId = useSelector(
      (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const prepareFetchUri = () => {
    if (campaigns === true) {
      return "getAvailableNotificationsResources";
    }

    return "getAccountNotificationSettings";
  };

  const { data, status, error } = useData(prepareFetchUri(), {}, [accountId]);

  const prepareDeleteUri = () => {
    if (campaigns === true) {
      return "deleteAccountNotificationSettingsPersonnelResource";
    }

    return "deleteAccountNotificationSettingsPersonnel";
  };

  const prepareDeleteParams = () => {
    if (campaigns === true) {
      return {
        SubscriptionId: personDelete,
      };
    }

    return {
      UserId: personDelete,
    };
  };

  const {
    data: updateData,
    status: updateStatus,
    execute: updateExecute,
  } = useData(prepareDeleteUri(), prepareDeleteParams(), [personDelete], false);

  const {
    data: principalsData,
    status: principalsStatus,
    execute: principalsExecute,
  } = useData(
      "getAccountNotificationSettingsSubscriptionsPrincipals",
      {},
      [accountId],
      false
  );

  const { notificationPrincipals } = useSelector(
      (state) => state.setting
  );

  useEffect(() => {
    if (principalsStatus === "success") {
      reduxDispatch(savePrincipals(principalsData));
    }
  }, [principalsData, principalsStatus, reduxDispatch]);

  useEffect(() => {
    if (!notificationPrincipals && principalsStatus === "init")
      principalsExecute();
  }, [])

  useEffect(() => {
    setState(data);
  }, [data]);

  useEffect(() => {
    if (personDelete) updateExecute();
  }, [personDelete, updateExecute]);

  useEffect(() => {
    if (updateStatus === "success") {
      setState(updateData);
    }
  }, [updateData, updateStatus]);

  const handleSubscriptionSelect = (person) => {
    if (!campaigns) return;
    selectPerson(person.Id);
  };

  const handlePersonEdit = (uid) => {
    if (uid === personEdit) {
      setPersonEdit(null);
    } else {
      setPersonEdit(uid);
    }
  };

  const handlePersonDelete = (uid) => {
    setPersonDelete(uid);

    if (!campaigns || !selectedPersons.has(uid)) return;
    selectPerson(uid);
  };

  const handleUpdate = (update, uid) => {
    setState(update);

    if (!uid) setPersonEdit(null);
  };

  const channelInfo = (personnelChannelInfo, channel) => {
    let channelInfo = personnelChannelInfo?.filter(ch => ch.Channel === channel)
    if (channelInfo.length > 0) {
      channelInfo = channelInfo[0]
      let subscribedNotifications = channelInfo.SubscribedNotifications
      subscribedNotifications = subscribedNotifications.map(notification => {
        let principals = notification.Principals
        if (principals < notificationPrincipals) {
          let updatedPrincipals = notificationPrincipals?.map((principal) => {
            let principalToUpdate = principals.filter(subscribedNotificationPrincipal => subscribedNotificationPrincipal.Id === principal.Id)
            if (principalToUpdate.length > 0) {
              principalToUpdate = principalToUpdate[0]
              if ("Status" in principalToUpdate) {
                return {
                  ...principal,
                  "Status": principalToUpdate["Status"]
                }
              } else {
                return {
                  ...principal,
                  "Status": true
                }
              }
            } else {
              return {
                ...principal,
                "Status": false
              }
            }
          })
          return {
            ...notification,
            "Principals": updatedPrincipals
          }
        }
        else {
          return {
            ...notification,
            "Principals": principals
          }
        }

      })
      return {
        "Channel": channel,
        "SubscribedNotifications": subscribedNotifications
      }
    }
  }

  const modifyPersonnelsData = (personnels) => {
    return personnels?.map(personnel => {
      let channels = personnel.Channels
      let emailChannelInfo = channelInfo(channels, "Email")
      let smsChannelInfo = channelInfo(channels, "SMS")

      let result = []
      if (emailChannelInfo) {
        result.push(emailChannelInfo)
      }
      if (smsChannelInfo) {
        result.push(smsChannelInfo)
      }

      return {
        ...personnel,
        "Channels": [
            ...result
        ]
      }
    })
  }

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "pending" || status === "init" || state === null) {
      return <Loader />;
    }

    if (Array.isArray(state) === false) {
      return state?.Message || "Error";
    }

    // console.log("state is: ", state)
    // console.log("modifyPersonnelsData: ", modifyPersonnelsData(state))

    const personnel = modifyPersonnelsData(state)?.map((person) => {
      const el = (
        <React.Fragment key={person.UserId}>
          <li
            className={cn("SettingsNotifications-personsItem", {
              "SettingsNotifications-personsItem--processing":
                person.UserId === personDelete,
              "SettingsNotifications-personsItem--selectable": campaigns,
            })}
            onClick={() => handleSubscriptionSelect(person)}
          >
            <SettingsNotificationsPerson
              data={person}
              handlePersonEdit={handlePersonEdit}
              handlePersonDelete={handlePersonDelete}
              selected={selectedPersons.has(person.Id)}
              campaigns={campaigns}
            />
          </li>

          {personEdit === (campaigns ? person.Id : person.UserId) && (
            <SettingsNotificationsForm
              data={person}
              onUpdate={handleUpdate}
              type="Update"
              campaigns={campaigns}
            />
          )}
        </React.Fragment>
      );

      return el;
    });

    return personnel;
  };

  return (
    <div className="SettingsNotifications">
      <div className="SettingsNotifications-add">
        <button
          className="SettingsNotifications-addToggler"
          onClick={() => handlePersonEdit(-1)}
        >
          <div className="SettingsNotifications-addTogglerLabel">
            Add Personnel
          </div>
          <div className="SettingsNotifications-addTogglerIcon">
            <Svg w="9" h="6" i="markerDropdown" />
          </div>
        </button>
        {personEdit === -1 && (
          <SettingsNotificationsForm
            onUpdate={handleUpdate}
            type="Add"
            campaigns={campaigns}
          />
        )}
      </div>
      <div className="SettingsNotifications-persons">
        <h3 className="SettingsNotifications-personsHeading">
          Personnel in Database
        </h3>
        <ul className="SettingsNotifications-personsList">{render()}</ul>
      </div>
    </div>
  );
};

export default SettingsNotifications;
