import React, { createContext, useEffect, useReducer, useState } from "react";
import cn from "classnames";

import useData from "../../../hook/useData";

import "./SettingsCMS.scss";
import Svg from "../../Svg/Svg";
import SettingsCMSForm from "./SettingsCMSForm/SettingsCMSForm";
import Loader from "../../Loader/Loader";
import Modal from "@material-ui/core/Modal";
import FormElement from "../../FormElement/FormElement";
import SwitchBody from "./SettingsCMSModal/SwitchBody";
import { settingsCMSReducer } from "../../../state/reducers/SettingsCMS";
import { settingsCMS } from "../../../state/initState/SettingsCMS";

export const StoreContextSettingsCMSPage = createContext({});

const SettingsCMS = () => {
  const [stateCMS, dispatchCMS] = useReducer(settingsCMSReducer, settingsCMS);

  const { data: dealerChatCMSData, status: dealerChatCMSStatus, error: dealerChatCMSError } = useData(
    "getDealerChatCMSInfo",
    {},
    [],
    true
  );

  useEffect(() => {
    if (dealerChatCMSData?.topics) {
      dispatchCMS({ type: "initTopics", value: dealerChatCMSData?.topics});
    }
  }, [dealerChatCMSData]);

  const handleQuestionEdit = (index) => {
    if (index === stateCMS.questionIndex) {
      dispatchCMS({ type: "questionIndexEdit", value: null})
    } else {
      dispatchCMS({ type: "questionIndexEdit", value: index})
    }
  };

  const addTopicHandler = () => {
    dispatchCMS({ type: "modelTypeEdit", value: "topic"});
    dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: true})
  }

  const addQuestionHandler = (topicId) => {
    dispatchCMS({ type: "initCurrentTopicId", value: topicId });
    dispatchCMS({ type: "modelTypeEdit", value: "question"});
    dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: true})
  }

  const deleteTopicHandler = (topicId, type) => {
    dispatchCMS({ type: "initDeleteState", value: { type, state: { delete_information: { topic_id: topicId }, key: "topic" } }})
    dispatchCMS({ type: "modelTypeEdit", value: "delete"})
    dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: true})
  }

  const deleteQuestionHandler = (topicId, questionId, type) => {
    dispatchCMS({ type: "initDeleteState", value: { type, state: { delete_information: { topic_id: topicId, question_id: questionId }, key: "question" } }})
    dispatchCMS({ type: "modelTypeEdit", value: "delete"})
    dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: true})
  }

  const renderQuestion = (questionEl, topicId, index) => {

    return (
      <>
          <li key={index} className="SettingsCMS-questionItem">
            <div className="SettingsCMS-question">
              <button className="SettingsCMS-questionToggler" onClick={(e) => {
                e.stopPropagation();
                handleQuestionEdit(questionEl?.question_id);
              }}>
                <div className="SettingsCMS-questionIcon">
                  Q
                </div>
                <div className="SettingsCMS-questionTogglerLabel">
                  <p>
                    {questionEl?.title}
                  </p>
                  {!questionEl?.answer &&
                    <div className="SettingsCMS-noAnswer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <path id="circle-exclamation-solid_2_" dataName="circle-exclamation-solid (2)" d="M6,12A6,6,0,1,0,0,6,6,6,0,0,0,6,12ZM6,3a.561.561,0,0,1,.563.563V6.188a.563.563,0,0,1-1.125,0V3.563A.561.561,0,0,1,6,3ZM5.25,8.25A.75.75,0,1,1,6,9,.75.75,0,0,1,5.25,8.25Z" fill="#d62b56"/>
                      </svg>
                    </div>
                  }
                </div>
                <div className="SettingsCMS-questionTogglerIcon">
                  <Svg w="9" h="6" i="markerDropdown" />
                </div>
              </button>
              <div className="SettingsCMS-questionDeleteBtn">
                {
                    questionEl?.custom &&
                    <button type="button" className="BusinessHoursDate-action BusinessHoursDate-action--delete" onClick={() => deleteQuestionHandler(topicId, questionEl?.question_id, "question")}>
                        <Svg w="18" h="18" i="garbage" />
                    </button>
                }
              </div>
            </div>
          </li>
          {stateCMS.questionIndex === questionEl?.question_id && (<SettingsCMSForm answer={questionEl?.answer} questionId={questionEl?.question_id} topicId={topicId} />)}
      </>
    );
  }

  const renderTopics = (topicsEl, index) => {

    return (
      <div key={index} className="SettingsCMS-topics">
        <div className="SettingsCMS-topicsTitle">
          <h3 className="SettingsCMS-topicsHeading">
            {topicsEl?.label}
          </h3>
          {
              topicsEl?.custom &&
              <button type="button" className="BusinessHoursDate-action BusinessHoursDate-action--delete" onClick={() => deleteTopicHandler(topicsEl?.topic_id, "topic")}>
                  <Svg w="18" h="18" i="garbage" />
              </button>
          }
        </div>
        <ul className="SettingsCMS-questionsList">
          {
            !!topicsEl?.questions?.length && topicsEl?.questions.map((el, index) => (
              renderQuestion(el, topicsEl?.topic_id, index)
            ))
          }
          <li className="SettingsCMS-questionItem">
            <div className="SettingsCMS-addQuestion">
              <button type="button" className="SettingsCMS-addQuestionButton" onClick={() => addQuestionHandler(topicsEl?.topic_id)}>
                <div className="SettingsCMS-addQuestionIcon"> <Svg w="32" h="32" i="add" /> </div>
                <div className="SettingsCMS-addQuestionLabel">
                  Add Question
                </div>
              </button>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  if (dealerChatCMSStatus === "error") {
    return dealerChatCMSError || "Error";
  }

  if (dealerChatCMSStatus === "pending") {
    return <Loader />;
  }

  return (
    <>
      <StoreContextSettingsCMSPage.Provider value={{ stateCMS, dispatchCMS }}>
        <div className="SettingsCMS">
          { 
            !!stateCMS?.topics?.length && stateCMS.topics.map((el, index) => (
              renderTopics(el, index)
            ))
          }
          <div className="SettingsCMS-addQuestion" style={{ marginTop: "3rem" }}>
              <button type="button" className="SettingsCMS-addQuestionButton" onClick={() => addTopicHandler()}>
                <div className="SettingsCMS-addQuestionIcon"> <Svg w="32" h="32" i="add" /> </div>
                <div className="SettingsCMS-addQuestionLabel">
                  Add Topic
                </div>
              </button>
            </div>
        </div>
        <Modal 
            open={stateCMS.openModal}
            onClose={() => dispatchCMS({ type: "changePrimitiveType", propertyId: "openModal", value: false})}
            className="SettingsCMS-Modal"
          >
            <SwitchBody type={stateCMS.modelType} />
        </Modal>
      </StoreContextSettingsCMSPage.Provider>
    </>
  );
};

export default SettingsCMS;
